import React from 'react';
import PropTypes from 'prop-types';

import YoutubeIframe from './YoutubeIframe';

const Embed = ({ element, slice }) => {
  let EmbedComponent
  
  if (slice) {
    EmbedComponent = (
      <React.Fragment>
        {element.map(item => (
          <YoutubeIframe 
            key={item.youtube_embed.embed_url}
            data={item.youtube_embed}
            start={parseInt(item.start_time)}
          />
        ))}
      </React.Fragment>
    )
  } else {
    EmbedComponent = <YoutubeIframe data={element.oembed} />
  }
  return <React.Fragment>{EmbedComponent}</React.Fragment>
}

Embed.propTypes = {
  element: PropTypes.array,
  slice: PropTypes.bool
}

export default Embed
