import React from 'react';
import LazyLoad from 'react-lazyload'
import { StyledEmbed } from '../../styled'
import PropTypes from 'prop-types';

const Iframe = ({data, start}) => {
  const videoIdIndex = data.embed_url.lastIndexOf('/');
  const videoUrl = data.embed_url.slice(videoIdIndex+1)
  const videoHeight = data.height;

  let videoSrc;

  if (data.embed_url.includes('vimeo.com')){
    videoSrc = data.embed_url;
  } else {

    if (data.embed_url.includes('embed')){
      videoSrc = `${data.embed_url}?rel=0&start=${start && start}` 
    } else {
      videoSrc = `${data.provider_url}embed/${videoUrl}?rel=0&start=${start && start}`
    }
  }
    
  return (
    <LazyLoad 
      height={videoHeight}
      once
      placeholder={
        <iframe height={videoHeight} data-video={videoUrl} />
      }
    >
      <StyledEmbed className="oembedStyle">          
        <iframe 
          src={data.embed_url.includes('embed') 
            ? `${data.embed_url}?rel=0&start=${start && start}` 
            : `${data.provider_url}embed/${videoUrl}?rel=0&start=${start && start}`}
          frameBorder={0}
          allowFullScreen={true}
        />         
      </StyledEmbed>
    </LazyLoad>
  )
};

Iframe.propTypes = {
    data: PropTypes.object,
    start: PropTypes.number
};

export default Iframe;